import { userInfo } from "../tools/util/homeApi.js"
import {
    setUserInfo
} from '../tools/util/storage.js';


export function headerTab() {
    var pathname = window.location.pathname;
    if (pathname == '/' || pathname == '/index.html') {
        return "1"
    } else if (pathname == '/method.html') {
        return "2"
    } else if (pathname == '/chart.html') {
        return "3"
    } else if (pathname == '/data.html') {
        return "4"
    } else if (pathname == '/class.html') {
        return "5"
    } else if (pathname == '/live.html') {
        return "6"
    }
}
/**
 * 获取url参数
 * @returns 
 */
export function getQueryParams() {
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    const params = {};
    urlParams.forEach((value, key) => {
        params[key] = value;
    });
    return params;
}
/**
 * 通过priceTick计算小数点  num即priceTick
 * @returns 
 */
export function getDecimalPrice(num) {
    var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/)
    if (!match) {
        return 0
    }
    if (match[1]) {
        return match[1].length <= 3 ? match[1].length : 1
    } else {
        return 0
    }
}

export function refreshUserInfo() {
    userInfo({}).then(res => {
        if (res.code == 200) {
            setUserInfo(JSON.stringify(res.result))
            location.reload();
        }
    })
}

//通知打开客服弹窗
export function IM_OPEN_SERVICE(status) {
    const event = new CustomEvent('IM_OPEN_SERVICE', { detail: status });
    window.dispatchEvent(event);
}
//IM状态通知
export function IM_STATUS_NOTIFY(status) {
     const event = new CustomEvent('IM_STATUS', { detail: status });
     window.dispatchEvent(event);
}
//IM  event_talk通知
export function IM_EVENT_TALK_NOTIFY(data) {
    const event = new CustomEvent('IM_EVENT_TALK', { detail: data });
    window.dispatchEvent(event);
}
//IM  event_talk_keyboard通知
export function IM_EVENT_TALK_KEYBORD_NOTIFY(data) {
    const event = new CustomEvent('IM_EVENT_TALK_KEYBORD', { detail: data });
    window.dispatchEvent(event);
}
//IM  event_login通知
export function IM_EVENT_LOGIN_NOTIFY(data) {
    const event = new CustomEvent('IM_EVENT_LOGIN', { detail: data });
    window.dispatchEvent(event);
}
//通知chart资讯重新获取对应合约下的资讯
export function toChangeChartNews_NOTIFY(data) {
    const event = new CustomEvent('toChangeChartNews', { detail: data });
    window.dispatchEvent(event);
}
//通知chart.js切换指标
export function toAddChartIndicator_NOTIFY(data) {
    const event = new CustomEvent('toAddChartIndicator', { detail: data });
    window.dispatchEvent(event);
}

//通知tickList socketIO连接成功
export function tickConnect_NOTIFY(data) {
    const event = new CustomEvent('tickConnect', { detail: data });
    window.dispatchEvent(event);
}